<template>

  <overly background="bg-gray-200" v-if="showing" @click.prevent="onClick">

    <div class="bg-white p-5 w-full md:w-9/12 lg:w-7/12 rounded shadow-lg relative border-4 border-blue-200 sticky top-0 bottom-0">
      <overly background="bg-gray-100" style="z-index: 1 !important;" loading v-if="loading"></overly>

      <div class="absolute -right-2 -top-2" style="z-index: 2 !important;">
        <btn class="rounded-full"  variant="warning" @click.prevent="showing = !showing " :disabled="loading">&times;</btn>
      </div>

      <div class="mb-4 w-full flex justify-between border-b py-1 justify-center z-50">
        <div class="font-semibold">Payments</div>
      </div>

      <div class="p-4" v-if="confirmation_box && active_payment">
        <p class="mt-4 mb-4 text-center text-base text-red-600">Are you sure want to cancel payment?</p>

        <p class="mb-4">
          <strong class="block font-semibold text-gray-400">Transaction ID</strong>
          <span>{{active_payment.transaction}}</span>
        </p>

        <p class="mb-4">
          <strong class="block font-semibold text-gray-400">Transaction Time</strong>
          <span>{{active_payment.createdAt}}</span>
        </p>

        <div class="text-red-500 text-center">{{ message }}</div>

        <div class="flex justify-between mt-8">
          <btn variant="danger" @click="onYesClick">Yes</btn>
          <btn variant="info" @click.prevent="confirmation_box=false;active_payment=null">No</btn>
        </div>
      </div>

      <div v-if="!confirmation_box">
        <div v-for="payment in payments" :key="payment.id"
             class="grid grid-cols-1 lg:grid-cols-3 items-center border shadow-sm mb-1 bg-yellow-50">
          <div class="px-4 py-2">
            <div class="text-gray-500 text-xs">Created</div>
            <div>{{ payment.createdAt }}</div>
          </div>
          <div class="px-4 py-2">
            <div class="text-gray-500 text-xs">Transaction</div>
            {{ payment.transaction }}
          </div>

          <div class="px-4 py-2 text-center md:text-right">
            <btn variant="danger" @click.prevent="onCancelPaymentClick(payment)">Cancel Payment</btn>
          </div>
        </div>
      </div>

    </div>

  </overly>
</template>

<script>
import Overly from "../../../components/overly";
import Btn from "../../../components/btn";
import Spinner from "../../../components/spinner";
import axios from "../../../store/axios";
import FlashMessage from "../../../components/flash-message";

export default {
  name: "payment-list",
  components: {FlashMessage, Spinner, Btn, Overly},
  props: {
    payments: {},
    modelValue:{type:Boolean, default: false}
  },
  data(){
    return {
      showing: this.modelValue,
      confirmation_box: false,
      active_payment:null,
      loading: false,
      message: ''
    }
  },
  methods: {
    onCancelPaymentClick(payment){
      this.active_payment = payment;
      this.confirmation_box = true;
    },
    onClick(e){

    },
    onYesClick(){
      this.loading = true;

      if( this.active_payment ) {
        axios().patch(
            `/student/payments/${this.active_payment.id}/cancel`,
            {
              payment_slip: this.active_payment.payment_slip,
              transaction: this.active_payment.transaction,
            }
        ).then(() => {

          this.$emit('payment-update');
          this.showing = false;

        }).catch(({response}) => {
          const statusCode = (response || {}).status;
          const statusData = (response || {}).data || {};
          console.log({response})
          this.message = statusData.message || (response || {}).statusText

          if( !this.message ) {
            switch (statusCode) {
              case 404:
                this.message = "Not Found";
                break;
              case 403:
                this.message = "Access Forbidden";
                break;
            }
          }

        }).finally(( ) => this.loading = false )
      }

    }
  },
  watch:{
    showing(showing){
      this.$emit( 'update:modelValue', showing );
    },
    modelValue(showing){
      this.active_payment = null;
      this.confirmation_box = false;
      this.loading = false;
      this.showing = showing;
    }
  }
}
</script>

<style scoped>

</style>