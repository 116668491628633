<template>

  <container class="relative max-w-6xl shadow bg-white mt-3 mb-6 rounded"
             :loading="pageLoading" min-height="300"
             :text-on-loading="loadingText" >

    <template v-slot:heading>


      <div class="text-sm text-orange-600 mt-4 pt-4"
           v-if="!paymentTimeIsUnlimited && !paymentCompleted && !partiallyPaid">
        <span>Your payment deadline will expire in</span>
        <span class="font-semibold">
          <span class="mx-1">{{paymentRemainingTime.days}} Day</span>
          <span class="mx-1">{{paymentRemainingTime.hours}} Hour</span>
          <span class="mx-1">{{paymentRemainingTime.minutes}} Minute</span>
          <span class="mx-1">{{paymentRemainingTime.seconds}} Second</span>
        </span>
      </div>

    </template>


    <div class="flex justify-center border-b mb-6">

      <payment-list :payments="unapprovedPayments"
                    v-model="unapprovedPaymentListOpen"
                    @payment-update="loadOrderData"
      ></payment-list>

      <overly background="bg-orange-800"
              opacity="opacity-80"
              v-if="!paymentTimeIsUnlimited  && paymentRemainingSeconds <= 0 && !partiallyPaid ">
        <div class="text-center">
          <div class="text-white text-xl mb-2 font-semibold">Your payment time has been expired</div>
          <div class="text-green-100  text-lg mb-2">Please try another course</div>
          <div class="text-white"><btn router-path="/courses" variant="success">Courses</btn></div>
        </div>
      </overly>

      <form method="post"
            @submit.prevent="processDataForPayment"
            class="w-full px-4 transition relative"
            v-fade-in="!pageLoading"
            v-if="step_number==1"
      >
        <h2 class="text-2xl border-b pb-2 pt-3">Payment</h2>
        <section class="text-gray-600 body-font">
          <div class="container  mx-auto grid grid-cols-12 gap-6"
               :class="{'flex-col items-center': !hasLectureSheet}">
            <div  class="relative lg:pb-24 flex flex-col col-span-full
                  lg:col-span-8" :class="{' ':!hasLectureSheet,'col-span-8':hasLectureSheet}" >
              <div class="text-dark-50 line-height-lg">
                <div class="w-full mx-auto overflow-auto">
                  <table class="table-auto w-full text-left whitespace-no-wrap" v-if="hasLectureSheet && participant">
                    <participant-item :participant="participant"></participant-item>
                  </table>
                </div>
              </div>
            </div>

            <div class=" col-span-4 flex flex-col md:mt-15 mt-0 pt-8 col-span-full lg:col-span-4 " >

              <order-details :has-lecture-sheet="hasLectureSheet"
                             :programPrice="programPrice"
                             :programDiscount="programDiscount"
                             :orderUsedDiscount="orderUsedDiscount"
                             :page-loading="pageLoading"
                             :total-paid="(order || {totalPaid:0}).totalPaid"
                             @total-due-zero="handleAfterPaymentCompleted"
              >
              </order-details>

            </div>

          </div>
        </section>


        <div class="mt-4">
          <div class="text-right text-xs text-yellow-700" v-if="unapprovedPayments.length">

            <p class="mb-1">
              <strong>Note: </strong> You can't checkout now, you have some unapproved payment(s).
            </p>
            <p class="mb-1">
              If you add more payment you need to cancel all unapproved payment.
              <btn size="xs" variant="info-outline" @click.prevent="unapprovedPaymentListOpen=true">Show All Unaproved Payments</btn>
            </p>
            <p>
              Or, Please wait for payment approval. You can contact to office.
            </p>

          </div>
        </div>

        <div class="sticky bottom-0 -mx-4 right-0 left-0 px-4 col-span-full bg-white py-4 mt-8 rounded-b"
             style="box-shadow: 0 -2px 5px rgba(10,10,10, 0.2)">
          <div class="flex justify-between w-full items-center">
            <div>
              <btn variant="success" router-path="/my/courses">Courses</btn>
            </div>


            <div class="flex gap-2 items-center">
              <btn variant="info" :disabled="unapprovedPayments.length">Checkout</btn>
            </div>
          </div>
        </div>

        

      </form>


      <checkout
          v-if="step_number==2"
          :has-lecture-sheet="hasLectureSheet"
          :program-price="programPrice"
          :program-discount="programDiscount"
          :orderUsedDiscount="orderUsedDiscount"
          :page-loading="pageLoading"
          :order="order"
          :handle-after-payment-completed="handleAfterPaymentCompleted"
          :area-data="areaData"
          :payment-token="payment_token"
      ></checkout>

      
    </div>
  </container>

</template>

<script>

import Container from "../../layouts/container";
import Btn from "../../../components/btn";
import ParticipantItem from "./components/ParticipantItem";
import area from "../../../mixins/area";
import {mapGetters, mapState} from "vuex";
import OrderDetails from "./components/order-item/order-details";
import AreaSelectionInputs from "./components/order-item/area-selection-inputs";
import Overly from "../../../components/overly";
import Checkout from "./checkout.vue";
import PaymentList from "./payment-list";

export default {
  name: "payments",
  components: {
    PaymentList,
    Checkout,
    Overly,
    AreaSelectionInputs, OrderDetails, ParticipantItem, Btn, Container,
  },
  data() {
    return {
      paymentCompleted: false,
      areaData: {  },
      pageLoading: true,
      data: {},
      paymentDataTimer: 0,
      getwayExpired: false,
      orderAddress: '',
      order:null,
      participant:null,
      loadingText: 'Loading...',
      paymentRemainingSeconds:0,
      paymentTimeIsUnlimited:true,
      partiallyPaid: false,
      payment_token: null,
      unapprovedPaymentListOpen: false
    }
  },
  methods: {
    loadOrderData(){
      if( this.paymentableType === 'program' ) {

        this.getParticipant({
          with: 'products-and-order-item,order-and-items,order-and-payments',
          additional: 'order-data',
          payment_token: 'true'
        })
            .then( ({ participant, order, payment_token }) => {

              this.participant = participant;
              this.orderUsedDiscount = order.totalUsedDiscount;

              this.order = order;

              this.payment_token = payment_token;

              this.areaData.divisionId = order.divisionId;
              this.areaData.districtId = order.districtId;
              this.areaData.areaId = order.areaId;
              this.areaData.address = order.shippingAddress;

            })
            .catch(data => console.log("What Happend?", data))
            .finally( () => this.pageLoading = false );
      }
    },
    handleAfterPaymentCompleted(){
      this.paymentCompleted = true;
      this.$router.push( '/my/courses' );
    },
    getOrder( params = { with: 'payments' }){
      return this.$store.dispatch( 'order/single' , {
        params, paymentableId: this.paymentableId, paymentableType: this.paymentableType
      });
    },
    getParticipant( params = { with: 'order,payments' } ){
      return this.$store.dispatch( 'participant/single' , {
        params, programId: this.paymentableId
      })
    },
    submit_form(){
      const form = document.getElementById('process-form-data');
      if( form ) {
        form.submit();
      }

    },
    processDataForPayment() {

      const participantId = this.paymentableType == 'participant' ? this.paymentableId: this.participant.id;

      this.pageLoading = true;

      let orderItems = { };

      this.changedOrderItems.filter(item => !( item.isFree || item.isMandatory ) ).forEach( item => {

        orderItems[item.marketplaceId] = {
          quantity:item.quantity,
          key: item.key
        };

      });

      
      // return null;
      if( this.order && this.order.orderId ) {

        this.loadingText = "Data Saving...";

        this.$store.dispatch('order/saveItems', { orderItems, orderId: this.order.orderId, reference: location.href } ).then( ( ) => {

          this.loadingText = "Processing for payment...";

          this.$extendRouteQueryParams({ step:'checkout' });

        }).finally(() => this.pageLoading = false );
      }
    },

    reduceTime( ){
      if( this.paymentRemainingSeconds > 0 ) {
        this.paymentRemainingSeconds--;
      }
    },

    updatePaymentRemainingTime(){
      if( this.paymentRemainingSeconds !== null && this.paymentRemainingSeconds > 0 ) {
        setInterval( this.reduceTime, 1000 );
      }
    }
  },
  computed: {
    step_number(){
      if( this.$route.query.step ) {
        const numbers = ['initial','checkout','payment'];
        return numbers.indexOf(this.$route.query.step)+1;
      }
      return 1;
    },
    orderUsedDiscount(){
      return (this.order || {totalUsedDiscount: 0}).totalUsedDiscount
    },
    paymentRemainingTime( ){

      const days = Math.floor( ( this.paymentRemainingSeconds / (  60 * 60 * 24  ) ) );
      const hours = Math.floor( ( this.paymentRemainingSeconds / ( 60 * 60 ) ) % 24 );
      const minutes = Math.floor( ( this.paymentRemainingSeconds / (60) ) % 60  );
      const seconds = this.paymentRemainingSeconds % 60;

      return {
          days,hours,minutes,seconds
      };

    },
    ...mapGetters({
      programProductData: 'order/programProductData',
      programMandatoryProductData: 'order/programMandatoryProductData',
    }),
    ...mapState({
      changedOrderItems: state => Array.isArray( state.order.changedOrderItems ) ? state.order.changedOrderItems:[]
    }),
    programPrice(){
      return ((this.participant || {}).programPrice) + this.programMandatoryProductData.price;
    },
    programDiscount(){
      return this.programMandatoryProductData.discount;
    },
    participationList(){
      return [];
    },
    hasLectureSheet(){
      return true;
    },
    paymentableType(){
      return this.$route.params.paymentableType;
    },
    paymentableId(){
      return this.$route.params.paymentableId;
    },
    unapprovedPayments(){

      if( this.order && (this.order.payments && Array.isArray( this.order.payments ) ) ){
          return this.order.payments.filter( (payment) => {
            return payment.status === 'Pending'
          })
      }

      return [];

    }
  },

  created( ) {
    this.updatePaymentRemainingTime();
    //this.loadOrderData();
  },
  mounted(){
    
    this.loadOrderData();
  },
  watch:{
    participant( participant ){
      if( participant ) {
        this.partiallyPaid = participant.paidAmount > 0;
        this.paymentRemainingSeconds = participant.paymentExpireSeconds;
        this.paymentTimeIsUnlimited = participant.paymentTimeIsUnlimited;
        this.updatePaymentRemainingTime();
      }
    }
  }

}
</script>

<style scoped>
</style>