<template>
  <tr>

    <td colspan="6" class="px-4 py-1 border-b border-gray-200 text-gray-900">

      <table class="w-full">

        <tr v-for="(packageProduct, serial) in packageProducts">
          <td class="px-4 py-1 border-gray-200 text-gray-900" >{{ serial+1 }}</td>
          <td class="px-4 py-1 border-gray-200 text-gray-900" colspan="10">{{ packageProduct.displayName }}</td>
          <td class="px-4 py-1 border-gray-200 text-gray-900">{{ packageProduct.quantity }}</td>

<!--          <td class="px-4 py-1 border-gray-200 text-gray-900">{{ packageProduct[keys.price] }}</td>-->
<!--          <td class="px-4 py-1 border-gray-200 text-gray-900">{{ (packageProduct[keys.quantity] || 1) * packageProduct[keys.price] }}</td>-->
        </tr>

      </table>

    </td>

  </tr>
</template>

<script>
export default {
  name: "order-item-package-products",
  props: ['packageProducts']
}
</script>

<style scoped>

</style>