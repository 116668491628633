<template>
  <tr>

    <td :class="tdClass" class="px-4 py-1 border-gray-200 text-gray-900 min-60" v-if="sl">
      {{sl}}
    </td>
    <td :class="tdClass" class="px-4 py-1 border-gray-200 text-gray-900 cursor-pointer min-60" @click="openPackageProducts">

      <div>{{orderItem.title}}</div>
<!--      <div>{{orderItem.key}}</div>-->

    </td>
    <td :class="tdClass" class="px-4 py-1 border-gray-200 text-gray-900 text-right min-60">
      {{ orderItem.price }}
    </td>
    <td :class="tdClass" class="px-4 py-1 border-gray-200 text-gray-900 text-right min-60">
      {{ orderItem.quantity }}
    </td>

    <td :class="tdClass"  class="px-4 py-1 border-gray-200  text-right text-base min-60">

      <money-amount class="line-through block font-semibold text-right text-yellow-400" :amount="subTotal" v-if="subTotal > subTotalDiscounted" >
        <template #suffix>/-</template>
      </money-amount>

      <money-amount class="block font-semibold text-right text-green-800" :amount="subTotalDiscounted">
        <template #suffix>/-</template>
      </money-amount>

    </td>

    <td  :class="tdClass"  class="w-6 text-center border-gray-200 min-60">

      <label>

      <div v-if="isCustomItem">
        <btn variant="danger"
             size="xs"
             class="px-2"
             @click="$emit( 'change', { orderItem, event: $event } )"
        >&times;</btn>
      </div>

      <input v-else
             type="checkbox"
             @change="$emit( 'change', { orderItem, event: $event } )"
             :checked="orderItem.isFree || orderItem.isMandatory || orderItem.ordered"
             :disabled="orderItem.isFree || orderItem.isMandatory"
      >

<!--        <span>Check</span>-->
      </label>
    </td>

  </tr>

  <order-item-package-products v-if="orderItem.isPackage" v-show="packageProductOpen" :package-products="packageProducts">
  </order-item-package-products>

</template>

<script>
import MoneyAmount from "../../../../../components/money-amount";
import Btn from "../../../../../components/btn";
import OrderItemPackageProducts from "./order-item-package-products";

export default {
  name: "OrderItem",
  emits: ["change"],
  components: {OrderItemPackageProducts, Btn, MoneyAmount},
  props: {
    orderItem: { type: Object, default: {}, required: true },
    sl: { type: Number, default: null },
    isCustomItem: { type: Boolean, default: false },
    tdClass: {type: String, default: ''}
  },
  data(){

    return {
      packageProductOpen: false,
      testNumber: 0,
      orderItemChecked: false,
    }

  },
  computed: {
    packageProducts(){
      return []
    },
    subTotal(){
      return this.orderItem.price * this.orderItem.quantity;
    },
    subTotalDiscounted(){
      return this.orderItem.isFree ? 0 : ( this.orderItem.price * this.orderItem.quantity ) - this.orderItem.discount;
    }
  },
  methods: {

  }
}
</script>

<style scoped>
  .min-60 {
    height: 65px;
  }
</style>