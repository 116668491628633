
export default {
    data() {
        return {
            areaData: {},
            selectedDivisionId: '',
            selectedDistrictId: '',
            selectedAreaId: '',
            __divisionList: [],
            districts:[],
            areas:[],
            address: ''
        }
    },
    computed: {
        divisions( ){
            return this.__divisionList;
        },
    },

    watch:{
        selectedDivisionId( divisionId ){
           this.setSelectedDivision( divisionId );
        },
        selectedDistrictId( districtId ){
            this.setSelectedDistrict( districtId );
        },
        selectedAreaId(selectedAreaId){


            this._loadDivisionsIfNotLoaded( ).then(()=>{

                

                this.setSelectedDivision(this.selectedDivisionId);
                this.setSelectedDistrict(this.selectedDistrictId);
            });
        }

    },

    created() {
        this._loadDivisionsIfNotLoaded();
    },
    methods: {

        setDivisions( divisions ){
            if( Array.isArray(divisions)) {
                this.__divisionList = divisions;
            }
        },
        setSelectedDivision( divisionId ){
            const filteredDivisions = this.divisions.filter( division => division.id === divisionId );
            if( filteredDivisions[0] ) {
                this.districts =  filteredDivisions[0].districts || [];
            } else {
                this.districts = [];
            }
            return  [];
        },
        setSelectedDistrict( districtId ){

            this.areas = [];

            if( Array.isArray( this.districts ) ) {
                const filteredDistricts =  this.districts.filter( district => district.id === districtId );
                if( filteredDistricts[0] ) {
                    this.areas = filteredDistricts[0].areas
                }
            }

        },
        _loadDivisionsIfNotLoaded(){


            return new Promise((resolve, reject) => {

                this.$store.dispatch( 'area/loadDivisionsIfNotLoaded' )
                    .then( ( divisions ) => {
                        this.__divisionList = divisions;
                        resolve(divisions);
                    })
                    .catch( reject );
            });

        }

    },


}