<template>
  <div class="w-full mx-auto  pb-6 pt-4 mt-4 rounded">

    <div class="flex w-full  mb-4 gap-4">
      <div class="border-b border-dashed w-full"></div>
      <div class="w-96 font-semibold text-center mt-4">Select your area</div>
      <div class="border-b border-dashed w-full"></div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-1 gap-4">


      <input-dropdown :items="divisions"
                      v-model="selectedDivisionId"
                      size="xs" clearable class="mb-4 w-full"
                      searchable>
        <template v-slot:label>Division</template>
      </input-dropdown>


      <input-dropdown :items="districts"
                      v-model="selectedDistrictId"
                      size="xs" clearable
                      class="mb-4 w-full" searchable>
        <template v-slot:label>District</template>
      </input-dropdown>


      <input-dropdown :items="areas"
                      v-model="selectedAreaId"
                      size="xs" clearable class="mb-4 w-full" searchable>
        <template v-slot:label>Area</template>
      </input-dropdown>


      <div class="border rounded shadow border-2 px-1 px-3">
        <label>Address (<required-icon class="text-xl"/>)</label>
        <textarea class="w-full px-0 py-0.5 h-8 hover:shadow-none outline-0"
                  placeholder="Type your shipping address"
                  required v-model="address">
        </textarea>
      </div>
    </div>

  </div>
</template>

<script>

import RequiredIcon from "../../../../../components/required-icon";
import area from "../../../../../mixins/area";
import InputDropdown from "../../../../../components/input/input-dropdown";
export default {
  name: "area-selection-inputs",
  mixins: [ area ],
  components: {InputDropdown, RequiredIcon  },
  props:[ 'pageLoading', 'modelValue'],
  methods: {
    emitModelValue(){
      this.$emit('update:modelValue', {
        divisionId: this.selectedDivisionId,
        districtId: this.selectedDistrictId,
        areaId: this.selectedAreaId,
        address: this.address,
      })
    }
  },
  created() {
    if( typeof this.modelValue == 'object' ) {

      setTimeout( () => {
          this.selectedDivisionId   =   (this.modelValue || {}).divisionId;
          this.selectedDistrictId = (this.modelValue || {}).districtId;
          this.selectedAreaId       =   (this.modelValue || {}).areaId;
          this.address = (this.modelValue || {}).address;
      },1000)

    }
  },
  watch: {

    selectedDivisionId(){
      this.emitModelValue();
    },
    selectedDistrictId(){
      this.emitModelValue();
    },
    selectedAreaId(){
      this.emitModelValue();
    },
    address(){
      this.emitModelValue()
    }
  }

}
</script>

<style scoped>

</style>