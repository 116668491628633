<template>
    <tbody v-if="participant">
      <tr><th colspan="10" class="pt-2 border-b">Program</th></tr>
      <tr>
        <td colspan="4" class="text-sm md:text-xl border-b py-3">
          <div class="text-blue-800 leading-tight flex flex-grow items-center font-semibold" v-show="!loading">
            <span>{{ participant.displayName || '' }}</span>
          </div>
        </td>
        <td class="text-base md:text-xl  border-b text-right px-4 text-right">


          <money-amount class="block  flex gap-1
                              justify-end font-semibold
                              text-right text-yellow-500"
                        :amount="participant.programDiscountedPrice"
                        v-show="!loading"
          >
            <template #suffix>/-</template>
          </money-amount>

          <template v-if="participant.programDiscountedPrice !== participant.programPrice">
            <money-amount
                          class="block text-sm flex gap-1 justify-end line-through
                                  font-semibold text-right text-gray-400"
                          :amount="participant.programPrice"
                          v-show="!loading"
            >
              <template #suffix>/-</template>
            </money-amount>
          </template>

        </td>
        <td class="w-6 text-center border-gray-200 border-b">
          <input type="checkbox" name="" value="" :checked="true" disabled>
        </td>

      </tr>

      <tr>
        <th colspan="10" class="pt-4 border-b" v-if="mandatoryProgramProducts.length">Included</th>
      </tr>

      <order-item td-class="border-b border-red"
                  :key="item.key"
                  v-for="(item, index) in mandatoryProgramProducts"
                  :sl="index+1"
                  :order-item="item"
                  @change="orderItemChanged"
      >
      </order-item>

      <template v-if="otherProgramProducts.length > 0">
        <tr><th colspan="10" class="pt-4 border-b pt-10">More Lecture sheet/Books</th></tr>
        <order-item :key="item.key" v-for="(item, index) in otherProgramProducts" :sl="index+1" :order-item="item" @change="orderItemChanged"></order-item>
      </template>

    </tbody>

</template>

<script>

import MoneyAmount from "../../../../components/money-amount";
import OrderItem from "./order-item";
export default {
  name: "ParticipantItem",
  components: { OrderItem, MoneyAmount },
  props: {
    participant: { type: Object, default: {}, required: true },
    loading: { type: Boolean, default: false },
  },
  data(){
    return {

    }
  },
  computed: {
    mandatoryProgramProducts( ){
      let freeProducts = [];
      if( Array.isArray( this.$store.state.order.otherProductItems )) {
        freeProducts = this.$store.state.order.otherProductItems.filter( item => item.isFree );
      }

      return [ ...this.$store.state.order.mandatoryProductItems, ...freeProducts ]
    },
    otherProgramProducts( ){
      // return this.$store.state.order.otherProductItems

      if( Array.isArray( this.$store.state.order.otherProductItems )) {
        return this.$store.state.order.otherProductItems.filter( item => !item.isFree );
      }

      return [];

    }
  },
  methods: {
    orderItemChanged(data){

      if( data.event && data.event.isTrusted && data.orderItem ) {
        this.$store.commit( 'order/setChangedOrderItems', data );
      }

    }
  }

}
</script>

<style scoped>

</style>