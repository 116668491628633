<template>

  <form method="post"
        class="w-full px-4"
        @submit.prevent="processDataForPayment">

    <h2 class="text-2xl border-b pb-2 pt-3">Checkout</h2>

    <order-details :has-lecture-sheet="hasLectureSheet"
                   :programPrice="programPrice"
                   :programDiscount="programDiscount"
                   :page-loading="pageLoading"
                   :order-used-discount="orderUsedDiscount"
                   :total-paid="(order || {totalPaid:0}).totalPaid"
                   @total-due-zero="handleAfterPaymentCompleted"
                   @due-amount="onOrderDetailsDueAmount"
    >
    </order-details>


    <div class="mt-5 border-b text-xl font-semibold mb-4">
      Payment Methods
    </div>

    <PaymentMethods
      :methods="payment_methods" 
      :payment-method-groups="payment_method_groups" 
      @changeMethod="onChangePaymentMethod"
      />
      <!-- v-model="selected_payment_method_id" -->

    <area-selection-inputs
      v-if="hasLectureSheet"
      v-model="_area_data"
    />

    <div class="mt-2 mb-6 flex flex-col items-end text-lg">

      <div>
        <span>
          Amount:
        </span> 
        <span  class="font-semibold">
          {{ total_due_amount }}
        </span>
      </div> 

      <div>
        <span>
          Payment Charge:
        </span>
        <span class="font-semibold">
          {{ chargeAmount }}
        </span> 
      </div>

      <div>
        <span>
          Total Payable
        </span>
        <span class="font-semibold">
          {{ total_due_amount + chargeAmount }}
        </span>
      </div>
      
       

      <!-- <MoneyAmount :amount="chargeAmount"></MoneyAmount> -->

    </div>


    <div>
      <label class="flex gap-4 items-center">
        <input type="checkbox" v-model="terms_and_condition_agreement" @change="showTermsAndCondition" class="h-4 w-4" required>
        <span>
          <span>I agree to the </span>
          <button type="button" @click="showTermsAndCondition">terms and condition</button>
        </span> 
      </label>
    </div>

    <div class="sticky bottom-0 -mx-4 right-0 left-0 px-4 col-span-full bg-white py-4 mt-8 rounded-b"
         style="box-shadow: 0 -2px 5px rgba(10,10,10, 0.2)">
      <div class="flex justify-between w-full">
        <btn variant="success" @click.prevent="back">Back</btn>
        <btn variant="info">Pay Now</btn>

      </div>
    </div>


    <div class="fixed inset-0 bg-gray-800  p-4 bg-opacity-90 flex items-center justify-center" style="z-index: 50000;" v-if="terms_and_condition_shown">
      <div class="absolute w-full lg:max-w-6xl top-16 bottom-16 px-4 lg:px-0" v-if="terms_and_condition_status=='done'">
        
        <div class="bg-white rounded-md shadow-lg flex flex-col h-full">

          <div class="bg-white py-4 left-0 right-0 border-b flex px-4  justify-between">
            <h2 class="text-lg font-semibold">{{ terms_and_condition.title }}</h2>
          </div>
          <div class="overflow-y-auto  bottom-16 top-16 p-4">
            <p v-html="terms_and_condition.content"></p>
          </div>
          <div class=" bottom-0 bg-white py-4 left-0 right-0 border-t flex px-4 justify-between">
            <btn variant="success" @click.prevent="terms_and_condition_agreement=true;terms_and_condition_shown=false">Agree</btn>
            <btn variant="danger" @click.prevent="terms_and_condition_agreement=false;terms_and_condition_shown=false">Disagree</btn>
          </div>
        </div>
      </div>
      <div v-else>
        <spinner></spinner>
      </div>
    </div>

  </form>

</template>

<script>

import OrderDetails from "./components/order-item/order-details.vue";
import AreaSelectionInputs from "./components/order-item/area-selection-inputs";
import axios from '@/store/axios.js';
import Btn from '@/components/btn.vue';
import Spinner from '../../../components/spinner.vue';
import PaymentMethods from "../../components/payments/payment-methods.vue";
import SSLCommerzButton from "../../components/payments/SSLCommerzButton.vue";



export default {
  emits: ['update:areaData'],
  props: [
    'hasLectureSheet',
    'programPrice',
    'programDiscount',
    'pageLoading',
    'order',
    'handleAfterPaymentCompleted',
    'areaData',
    'paymentToken',
    'orderUsedDiscount'
  ],
  name: "checkout",
  components: { AreaSelectionInputs, OrderDetails, Btn, Spinner, PaymentMethods,SSLCommerzButton },
  data() {

    return {
      _area_data: this.areaData,
      terms_and_condition_agreement: '',
      terms_and_condition: {
        content:'',
        title:'',
      },
      terms_and_condition_shown: false,
      terms_and_condition_status: '',
      payment_methods:[],
      payment_method_groups:[],
      selected_payment_method_id:null,
      selected_payment_method: {},
      total_due_amount: 0
    }

  },
  computed: {

    redirectUrl( ){
      const url = new URL(location.href);
      url.searchParams.set('step','initial');
      return url.toString()
    },
    chargeAmount( ){

      //console.log(this.selected_payment_method);
      if( this.total_due_amount && this.selected_payment_method.charge_percentage ) {
        return ( this.total_due_amount ) * ( this.selected_payment_method.charge_percentage / 100);
      }

      return 0;

    },

  },
  methods: {

    onOrderDetailsDueAmount({amount}){
      //alert('Ok');
      console.log({amount});
      this.total_due_amount = Number(amount);
    },

    async showTermsAndCondition(){
      this.terms_and_condition_shown = true;
      this.terms_and_condition_status = 'loading';
      try {
        this.terms_and_condition = ( await axios().get(`/pages?slug=terms-and-conditions` ) ).data.page;
        this.terms_and_condition_status = 'done';
        
      }catch (err){
        console.log(err)
        this.terms_and_condition_status = 'error';
      }
  
    },
    async processDataForPayment() {

      //alert('ok');
      const token = this.paymentToken;


      console.log({token, gateway_name: this.selected_payment_method});

      if( this.selected_payment_method.gateway_name === 'sslcommerze' ) {

        const cart_json = {}; 
        
        cart_json.token = token;
        cart_json.payment_method_id = this.selected_payment_method.id;
        cart_json.redirect_url = encodeURI(this.redirectUrl);
        
        const response = await axios().post( this.selected_payment_method.gateway_configuration.getaway_url , {cart_json: JSON.stringify(cart_json)});
        
        if( response.data.status ) {
          window.location = response.data.data;
        }
      } else {

        axios().put(`/student/update-order-data/${this.order.orderId}`, {
          area_id: this._area_data.areaId,
          shipping_address: this._area_data.address,
          redirect_url: this.redirectUrl,
          terms_and_condition_agreement: this.terms_and_condition_agreement ? 'yes':'no'
        }).then(
          async ({data}) => {
  
            this.$router.replace({
              path: `/my/payment/process`,
              query: {
                token,
                'payment-method-id': this.selected_payment_method.id,
                // redirect_url: encodeURI(this.redirectUrl)
              }
            });
          }
        );
      }

    },
    back() {
      this.$extendRouteQueryParams({'step': 'initial'}, true);
    },
    onChangePaymentMethod( paymentMethod ){
      this.selected_payment_method = paymentMethod;
        console.log( {paymentMethod} );
    },
    enqueueSSLCommerzScript() {
      var script = document.createElement("script"), 
          tag = document.getElementsByTagName("script")[0];
      //script.src = "https://seamless-epay.sslcommerz.com/embed.min.js?" + Math.random().toString(36).substring(7); //USE THIS FOR LIVE
      script.src = "https://sandbox.sslcommerz.com/embed.min.js?" + Math.random().toString(36).substring(7); // USE THIS FOR SANDBOX
      tag.parentNode.insertBefore(script, tag);
    }
  },
  async created(){
    const token = this.paymentToken;

    if( !token ) {
      this.$extendRouteQueryParams({step:'initial'}, true)
    }

    const {data:method_data} = (await axios().get(`/student/payment/methods`, { params: {token}} ));
    this.payment_methods = method_data.payment_methods;
    this.payment_method_groups = method_data.payment_method_groups;
    //this.enqueueSSLCommerzScript();
  },
  watch: {
    _area_data(value) {
      this.$emit('update:areaData', value);
    },
    areaData(ad) {
      this._area_data = ad;
    }

  }
}
</script>

<style scoped>

</style>