<template>
  <div class="w-full bg-gray-100  px-4 pb-6  rounded text-sm md:text-base ">

    <div class="flex  mt-4">
      <div class=" text-gray-900 font-semibold">Course Price</div>
      <div class=" text-green-600 flex-grow text-right">
        <money-amount class="block" :amount="programPrice" v-show="!pageLoading">
          <template #suffix>/-</template>
        </money-amount>
      </div>
    </div>

    <template v-if="hasLectureSheet">

      <div class="flex  mt-2">
        <div class=" text-gray-900 font-semibold">Book/Lecture sheet</div>
        <div class=" text-green-600 flex-grow text-right">
          <money-amount class="block" :amount="productsPrice" v-show="!pageLoading">
            <template #suffix>/-</template>
          </money-amount>
        </div>
      </div>

      <div class="flex  mt-2" v-if="getOrderData('deliveryCharge') > 0">
        <div class=" text-gray-900 font-semibold">Delivery Charge</div>
        <div class=" text-green-600 flex-grow text-right">
          <money-amount class="block" :amount="getOrderData('deliveryCharge')" v-show="!pageLoading">
            <template #suffix>/-</template>
          </money-amount>
        </div>
      </div>

      <div class="flex  mb-4  border-t ">
        <div class=" text-gray-900 font-semibold">Total</div>
        <div class=" text-green-600 flex-grow text-right">
          <money-amount class="block font-semibold" :amount="grandTotal" v-show="!pageLoading">
            <template #suffix>/-</template>
          </money-amount>
        </div>
      </div>

      <div class="flex text-lg" v-if="discount > 0">
        <div class="text-gray-900  ">Discount</div>
        <div class=" text-green-600 flex-grow text-right">
          <money-amount class="block" :amount="discount" v-show="!pageLoading">
            <template #suffix>/-</template>
            <template #prefix>-</template>
          </money-amount>
        </div>
      </div>

    </template>


    <div class="flex text-lg" v-if="hasLectureSheet && discountedTotal < grandTotal">
      <div class="text-gray-900  ">Payable Amount</div>
      <div class=" text-green-600 flex-grow text-right">
        <money-amount class="block" :amount="discountedTotal" v-show="!pageLoading">
          <template #suffix>/-</template>
        </money-amount>
      </div>
    </div>

    <div class="flex text-lg mt-3">
      <div class=" text-gray-900">Paid Amount</div>
      <div class="flex-grow text-right">
        <money-amount class=" block text-green-500 font-semibold" :amount="totalPaid" v-show="!pageLoading">
          <template #suffix>/-</template>
        </money-amount>
      </div>
    </div>

    <div class="flex text-lg border-t mt-6">
      <div class=" text-gray-900 font-semibold">Due Amount</div>
      <div class=" text-orange-600 font-bold flex-grow text-right" v-show="!pageLoading">
        <money-amount class="block" :amount="dueAmount">
          <template #suffix>/-</template>
        </money-amount>
      </div>
    </div>

  </div>

</template>

<script>
import MoneyAmount from "../../../../../components/money-amount";
import {mapGetters} from "vuex";
export default {
  name: "order-details",
  emits:['totalDueZero', 'dueAmount'],
  components: { MoneyAmount },
  props: [
    'pageLoading', 'hasLectureSheet','programPrice','programDiscount', 'totalPaid', 'orderUsedDiscount'
  ],
  computed: {
    ...mapGetters({
      programProductData: 'order/programProductData',
    }),
    productsPrice(){
      return this.programProductData.price || 0
    },
    discount(){

      return (this.programProductData.discount || 0) + (this.programDiscount);
    },
    discountedTotal(){
      return (this.grandTotal) - (this.discount)
    },
    grandTotal(){
      return ( this.productsPrice + this.programPrice )
    },
    dueAmount(){
      return Number(this.discountedTotal - (this.totalPaid || 0)).toFixed(2);
    }
  },
  methods: {
    getOrderData(key){
      const orderData = {
        productsPrice: 4
      };

      return orderData[key] || 0;
    }
  },
  mounted(){
    this.$emit('dueAmount', {amount:this.dueAmount})
  },
  watch:{
    dueAmount( amount ){
      this.$emit('dueAmount', {amount})
      if( amount <= 0) {
        this.$emit( 'totalDueZero' );
      }
    },
  }
}
</script>

<style scoped>

</style>